import analytics from './../utils/analytics';

interface UserData {
  name: string;
  factoryName: string;
  userId: number;
  role: string;
  email: string;
  phone: string;
}

export default function useAnaytics(): {
  sendEvent: (name: string, props: any) => void;
  identify: (id: number) => void;
  init: () => void;
  profileSet: (data: UserData) => void;
} {
  return {
    sendEvent: (name, props) => {
      analytics.sendEvent(name, props);
    },
    identify: (id) => {
      analytics.identify(id);
    },
    init: () => {
      analytics.init();
    },
    profileSet: (data) => {
      analytics.profileSet(data);
    },
  };
}
