import { FC, lazy, Suspense, useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Redirect } from 'react-router-dom';

import Maintenance from './components/Maintenance';
import RouteAuthenticated from './authenticatedRoute';
import RouteUnauthenticated from './unAuthenticatedRoute';
import MainPageLoader from './components/Loader/mainPageLoader';

import { axiosInstance } from './utils/axiosInterceptor';

const LazyPartialProductionLog = lazy(
  () => import('./containers/Orders/partialProductionLog')
);
const LazyLogin = lazy(() => import('./containers/Login'));
const LazyHome = lazy(() => import('./containers/Home'));
const LazyApp = lazy(() => import('./components/AppLink'));
const LazyProcess = lazy(() => import('./containers/Processes'));
const LazyMachine = lazy(() => import('./containers/Machines'));
const LazyEmployees = lazy(() => import('./containers/Employees'));
const LazyInventory = lazy(() => import('./containers/Inventory'));
const LazySetting = lazy(() => import('./containers/Setting'));
const LazyProcessDetails = lazy(
  () => import('./containers/Processes/processDetail')
);
const LazyMachineDetail = lazy(
  () => import('./containers/Machines/machineDetail')
);
const LazyEmployeDetail = lazy(
  () => import('./containers/Employees/employeeDetail')
);
const LazyDemo = lazy(() => import('./containers/Demo'));
const LazyMaterialDetail = lazy(
  () => import('./containers/Inventory/materialDetail')
);
const LazyProductDetail = lazy(
  () => import('./containers/Inventory/productDetail')
);
const LazyOrder = lazy(() => import('./containers/Orders'));
const LazyOrderDetails = lazy(() => import('./containers/Orders/orderDetails'));

const Routes: FC = () => {
  const [isAuth, setIsAuth] = useState(false);
  const fallbackUri = `${isAuth ? '/dashboard' : '/login'}`;

  const getRefreshToken = async () => {
    try {
      const data = await axiosInstance({
        url: `${process.env.REACT_APP_API_URL}/v1/auth/refresh-tokens`,
        method: 'post',
        headers: {
          'content-type': 'application/json',
        },
        data: {
          refreshToken: `${localStorage.getItem('refreshToken')}`,
        },
      });
      const { data: apiResponse } = data;
      if (apiResponse) {
        localStorage.setItem('token', apiResponse.access.token);
        localStorage.setItem('tokenExpires', apiResponse.access.expires);
        localStorage.setItem('refreshToken', apiResponse.refresh.token);
      } else {
        localStorage.clear();
      }
    } catch (error) {
      console.log(error);
      localStorage.clear();
    }
  };
  useEffect(() => {
    const refreshToken: string | null = localStorage.getItem('refreshToken');
    const token: string | null = localStorage.getItem('token');

    if (refreshToken && token) {
      setIsAuth(true);
      getRefreshToken();
    } else {
      localStorage.clear();
    }
  }, []);

  const maintenanceMode: string | null = localStorage.getItem('Maintenance');

  return (
    <Router>
      <Suspense fallback={<MainPageLoader />}>
        {maintenanceMode === 'on' ? (
          <Maintenance />
        ) : (
          <Switch>
            <RouteAuthenticated
              path="/processes/:id"
              component={LazyProcessDetails}
            />
            <RouteAuthenticated path="/processes" component={LazyProcess} />

            <RouteAuthenticated
              path="/machines/:id"
              component={LazyMachineDetail}
            />
            <RouteAuthenticated path="/machines" component={LazyMachine} />

            <RouteAuthenticated
              path="/employees/:id"
              component={LazyEmployeDetail}
            />
            <RouteAuthenticated path="/employees" component={LazyEmployees} />

            <RouteAuthenticated
              path="/material/:id"
              component={LazyMaterialDetail}
            />

            <RouteAuthenticated
              path="/product/:id"
              component={LazyProductDetail}
            />

            <RouteAuthenticated path="/inventory" component={LazyInventory} />

            <RouteAuthenticated
              path="/orders/:id/logs"
              component={LazyPartialProductionLog}
            />
            <RouteAuthenticated
              path="/orders/:id"
              component={LazyOrderDetails}
            />
            <RouteAuthenticated path="/orders" component={LazyOrder} />

            <RouteAuthenticated path="/dashboard" component={LazyHome} />
            <RouteAuthenticated path="/demo" component={LazyDemo} />
            <RouteAuthenticated path="/settings" component={LazySetting} />
            <RouteUnauthenticated path="/login" component={LazyLogin} />
            <RouteAuthenticated path="/app" component={LazyApp} />

            <Redirect to={fallbackUri} />
          </Switch>
        )}
      </Suspense>
    </Router>
  );
};

export default Routes;
