import { FC } from 'react';

import { RefreshIcon } from '@heroicons/react/outline';
import MaintenanceIMG from '../../assets/maintenance.png';

const Maintenance: FC = () => {
  return (
    <div className="h-screen w-full overflow-hidden">
      <div className="w-full flex justify-center items-center mt-28">
        <p className="text-silver-900 text-5xl font-medium">
          We will be back soon !
        </p>
      </div>
      <div className="w-full flex flex-col justify-center items-center text-silver-700 text-2xl leading-8 font-normal mt-8 tracking-wider">
        <p>Sorry for the inconvenience, we are performing maintenance </p>
        <p>at the moment. Please try again later.</p>
      </div>
      <div className="w-full flex justify-center items-center mt-10">
        <button
          className="text-green-700 hover:text-silver-800 bg-transparent shadow-none  text-2xl flex items-center"
          type="button"
          onClick={() => window.location.reload()}
        >
          <RefreshIcon className="h-6 w-6 mr-3 mt-px" />
          <span>Refresh Page</span>
        </button>
      </div>

      <div className="w-full flex justify-center items-center mt-10">
        <img src={MaintenanceIMG} alt="Maintenance Mode" className="h-full" />
      </div>
    </div>
  );
};

export default Maintenance;
