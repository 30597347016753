import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import App from './App';
import './index.css';

Sentry.init({
  dsn: `${process.env.REACT_APP_SENTRY_DSN}`,
  integrations: [new Integrations.BrowserTracing()],
  environment: `${process.env.REACT_APP_ENV}`,
  tracesSampleRate: 0.2,
});

ReactDOM.render(<App />, document.getElementById('root'));
