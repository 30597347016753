import mixpanel from 'mixpanel-browser';

const TRACKING_ID = process.env.REACT_APP_MIXPANEL_TRACKING_ID;

interface UserData {
  name: string;
  factoryName: string;
  userId: number;
  role: string;
  email: string;
  phone: string;
}

function init(): void {
  mixpanel.init(TRACKING_ID, { debug: false });
}

function identify(id: number): void {
  mixpanel.identify(id);
}

function sendEvent(name: string, properties): void {
  mixpanel.track(name, properties);
}
function profileSet(data: UserData): void {
  mixpanel.people.set({
    $name: data?.name,
    factoryName: data.factoryName,
    userId: data.userId,
    role: data?.role,
    $email: data?.email,
    phone: data?.phone,
  });
}

export default {
  init,
  identify,
  sendEvent,
  profileSet,
};
