import { Redirect, Route, RouteProps } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from './store/Reducer';
import { useHistory } from 'react-router-dom';
const RouteUnauthenticated: React.ComponentState = ({
  component: Component,
  path,
}: RouteProps) => {
  const history = useHistory();
  const token = localStorage.getItem('token') ? true : false;
  const { user } = useSelector((state: RootState) => state);

  if (token && user?.role === 'Admin') {
    return <Redirect to="/dashboard" />;
  }
  if (path === '/app') {
    const token = localStorage.getItem('token') ? true : false;
    if (token) {
      localStorage.removeItem('token');
    } else {
      history.push('/login');
    }
  }

  return <Route component={Component} path={path} />;
};

export default RouteUnauthenticated;
