import { Redirect, Route, RouteProps } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from './store/Reducer';

const RouteAuthenticated: React.ComponentState = ({
  component: Component,
  path,
}: RouteProps) => {
  const token = localStorage.getItem('token');
  const { user } = useSelector((state: RootState) => state);
  if (!token) {
    return <Redirect to="/login" />;
  }

  return user?.role === 'Admin' ? (
    <Route component={Component} path={path} />
  ) : (
    <Redirect to="/app" />
  );
};

export default RouteAuthenticated;
