import axios from 'axios';

export const axiosInstance: any = axios.create();
axiosInstance.interceptors.response.use(
  (response) => {
    localStorage.setItem('Maintenance', 'off');
    // Return a successful response back to the calling service
    return response;
  },
  (error) => {
    // Return any error which is not due to authentication back to the calling service
    if (error.response.status !== 401) {
      return new Promise((_resolve, reject) => {
        reject(error);
      });
    }
    if (error.response.status === 503) {
      localStorage.setItem('Maintenance', 'on');
    } else {
      localStorage.setItem('Maintenance', 'off');
    }
    // Logout user if token refresh didn't work
    window.location.reload();
  }
);
