import { createGlobalStyle } from 'styled-components';

export interface GlobalStylesProps {
  fontRoboto?: boolean;
  whiteColor?: boolean;
  lightGrey?: boolean;
}

type IGlobalStyles = GlobalStylesProps;

const GlobalStyles = createGlobalStyle<IGlobalStyles>`
  html {
    @media (max-width: 1366px) {
      font-size: 87.5%; // 14px
    }
    @media (min-width: 1367px) and (max-width: 1600px) {
      font-size: 100%; // 16px
    }
    @media (min-width: 1601px) {
      font-size: 112.5%; // 18px
    }
    // line-height: 1.2;
  }
  body {
    background-color: '#f8f8f8';
    margin: 0px;
  }
 
`;
export default GlobalStyles;
