import { FC } from 'react';

const MainPageLoader: FC = () => {
  return (
    <div className="h-screen w-full flex">
      <div className="h-full p-4 w-70">
        <div className="animate-pulse flex flex-col w-full h-full">
          <div className="h-full bg-gray-200 rounded w-full" />
        </div>
      </div>
      <div className="h-full p-4 flex-grow">
        <div className="animate-pulse flex flex-col w-full h-full">
          <div className="h-full bg-gray-200 rounded w-full" />
        </div>
      </div>
    </div>
  );
};

export default MainPageLoader;
