import { combineReducers } from 'redux';
import navReducer from './navReducer';
import saveUser from './saveUser';

export const reducers = combineReducers({
  collapseOpen: navReducer,
  user: saveUser,
});

export type RootState = ReturnType<typeof reducers>;
