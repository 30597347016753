import './style.css';
import { Provider } from 'react-redux';
import { ToastContainer, cssTransition } from 'react-toastify';
import * as Sentry from '@sentry/react';
import { PersistGate } from 'redux-persist/integration/react';
import { IntercomProvider } from 'react-use-intercom';
import { FC, lazy, Suspense, useEffect } from 'react';

import { store, persistor } from './store/store';

import Routes from './routes';

import GlobalStyles from './../src/components/GlobalStyles';

import { XIcon } from '@heroicons/react/solid';

import useWindowDimensions from './hooks/useDimensionsWithResize';
import useAnaytics from './hooks/useAnalytics';

const LazyApp = lazy(() => import('./components/AppLink'));

const toastStyleCustom = {
  borderRadius: '6px',
  width: 'fit-content',
};
const downAnimation = cssTransition({
  enter: 'animate-slideIn',
  exit: 'animate-slideOut',
});
const App: FC = () => {
  const { width } = useWindowDimensions();
  const { init } = useAnaytics();

  useEffect(() => {
    init();
  }, []);

  return width && width > 1023 ? (
    <div
      className="h-screen"
      id="mainContainer"
      onDrop={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
      onDragOver={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
        <Provider store={store}>
          <IntercomProvider
            appId={
              process.env.REACT_APP_INTERCOM_ID
                ? process.env.REACT_APP_INTERCOM_ID
                : ''
            }
            autoBoot
            initializeDelay={2000}
          >
            <PersistGate loading={null} persistor={persistor}>
              <>
                <ToastContainer
                  toastClassName={
                    'rounded-xl overflow-hidden cursor-pointer bg-black-100'
                  }
                  toastStyle={{ padding: '0px', minHeight: 'fit-content' }}
                  bodyClassName="p-0 m-0"
                  style={toastStyleCustom}
                  position="bottom-left"
                  closeButton={
                    <div className="flex py-4 px-3">
                      <button className="focus:outline-none" type="button">
                        <XIcon
                          className={`h-5 w-5 text-white`}
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  }
                  autoClose={2000}
                  transition={downAnimation}
                  hideProgressBar
                />
                <GlobalStyles />
                <Routes />
              </>
            </PersistGate>
          </IntercomProvider>
        </Provider>
      </Sentry.ErrorBoundary>
    </div>
  ) : (
    <Suspense fallback={null}>
      <LazyApp />
    </Suspense>
  );
};

export default App;
